import api from '@utils/api';

export const login = async (email, password) => {
  return new Promise(async (resolve, reject) => {
    try {
      const {data} = await api.post('login', {email, password});
      resolve(data.data);
    } catch (error) {
      reject(error.response.data);
    }
  });
};
