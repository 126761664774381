import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {login as loginService} from '@services/auth/auth.service';

const LOCAL_STORAGE_KEY = '@auth:user';

const retrieveUserFromLocalStorage = () => {
  const user = localStorage.getItem(LOCAL_STORAGE_KEY);
  return user ? JSON.parse(user) : null;
}

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }) => loginService(email, password),
);

export const getCredentials = createAsyncThunk(
  'auth/getCredentials',
  async (_, {rejectWithValue}) => {
    try {
      const auth = retrieveUserFromLocalStorage();

      if (!auth) {
        return rejectWithValue('User not found');
      }

      const { credentials, user } = auth;
      return { credentials, user};
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  error: '',
  auth: {
    access_token: '',
    token_type: '',
  },
  user: {
    id: '',
    name: '',
    username: '',
    email: '',
    profile_image: '',
    abilities: [],
    created_at: '',
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      Object.assign(state, initialState);
    }
  },
  extraReducers: builder => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });

    builder.addCase(login.fulfilled, (state, {payload}) => {
      const {access_token, token_type, user} = payload;
      const isAdmin = user.abilities.some(a => a.ability === 'admin');

      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify({ credentials: { access_token, token_type }, user }),
      );

      Object.assign(state, {
        isLoading: false,
        isLoggedIn: true,
        error: '',
        auth: { access_token, token_type },
        user: {...user, isAdmin},
      });
    });

    builder.addCase(login.rejected, (state, {error}) => {
      Object.assign(state, initialState, {error: error.message});
    });

    builder.addCase(getCredentials.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCredentials.fulfilled, (state, {payload}) => {
      const isAdmin = payload.user.abilities.some(a => a.ability === 'admin');

      Object.assign(state, {
        isLoading: false,
        isLoggedIn: true,
        auth: payload.credentials,
        user: {...payload.user, isAdmin},
      });

    });

    builder.addCase(getCredentials.rejected, (state) => {
      Object.assign(state, initialState, {isLoggedIn: false});
    });
  },
});

export const {logout} = authSlice.actions;
export const authReducer = state => state.auth;
export default authSlice.reducer;
